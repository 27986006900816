const routeName = 'module->Accounting->Report';
const baseUrl = '/xapi/Accounting/Report';
const moduleAjaxService = 'backend-service'

const routes = {
    path: 'Report',
    name: routeName,
    component: () => import('./View.vue'),
    children: [
        {
            path: 'tax-report',
            name: routeName + '->tax-report',
            component: () => import('./form/tax-report/View')
        },
        {
            path: 'control-report',
            name: routeName + '->control-report',
            component: () => import('./form/control-report/View')
        },
        {
            path: 'create',
            name: routeName + '->create',
            component: () => import('./create/View')
        },
        {
            path: 'list',
            name: routeName + '->list',
            component: () => import('./list/View'),
            children: [
                {
                    path: ':listPage',
                    name: routeName + '->list->page',
                    component: () => import('./list/page/View')
                }
            ]
        },
        {
            path: ':entityTitle',
            name: routeName + '->view',
            component: () => import('./view/View.vue')
        }/*,
      {
        path: 'external-component',
        name: routeName + '->external-component',
        component: () => $hks.loadExternalComponent('http://127.6.3.1:8000/xapi/UI/ModuleManager/getComponent/Accounting/Report/compName'),
      }*/
    ]
}

export const module = {
    info: {
        routeName,
        name: 'Report',
        caption: 'Accounting Report',
        namespace: 'Accounting',
        fullName: 'Accounting-Report'
        // viewGroup: 'general-modules'
    },
    vuexModules: ['api'],
    sideMenu: {
        notAllowedProfiles: [],
        topSeparator: false,
        caption: 'Report',
        icon: 'el-icon-folder',
        routeName: 'list->page',
        routeParams: {listPage: 1}
        /*children: [
          {
            caption: 'Create Entity',
            icon: 'el-icon-plus',
            routeName: 'create'
          },
          {
            caption: 'List Entities',
            icon: 'el-icon-more-outline',
            routeName: 'list->page',
            routeParams: { listPage: 1}
          }
        ]*/
    },
    router: {
        mainRouteName: 'portal-cpanel',
        routes: routes
    },
    onLoad: async function () {
        $xapi = {
            ...$xapi,
            ...$mapActions('Accounting-Report-api', {
                getAccountingReportDropdown: 'getDropDownList',
            }),
        }

        $services['Accounting-Report'] = {
            getReportColumns: function (arrNames) {
                let columns = []
                let len = arrNames.length
                for (let i = 0; i < len; i = i + 1) {
                    columns.push(this.columnsBluePrint[arrNames[i]])
                }
                return columns
            },
            columnsBluePrint: {
                business_name: {
                    label: 'Business Name',
                    width: 680,
                    from: 'api',
                    prop: 'business_name',
                    tdStyle: 'vertical-align: middle;',
                    style: '',
                    headStyle: 'padding-left: 10px;'
                },
                contact_name: {
                    label: 'Contact',
                    width: 400,
                    from: 'api',
                    prop: 'contact_name',
                    tdStyle: 'vertical-align: middle;',
                    style: 'text-align: center',
                    headStyle: 'text-align:center;',
                    defaultValue: '-'
                },
                comment: {
                    label: 'Comment',
                    width: 400,
                    from: 'func',
                    prop: '',
                    tdStyle: 'vertical-align: middle;',
                    style: 'text-align: center',
                    headStyle: 'text-align:center;',
                    defaultValue: '-',
                    func: function (cell, data) {
                        if(data.account_on_tax_check_management == 1) return 'SEE MANAGEMENT'
                        return ''
                    }
                },
                business_number__complete: {
                    label: 'Business #',
                    width: 400,
                    from: 'api',
                    prop: 'business_number__complete',
                    tdStyle: 'vertical-align: middle;',
                    style: 'text-align: center',
                    headStyle: 'text-align:center;',
                    defaultValue: '-'
                },
                business_number: {
                    label: 'Business #',
                    width: 400,
                    from: 'api',
                    prop: 'business_number',
                    tdStyle: 'vertical-align: middle;',
                    style: 'text-align: center',
                    headStyle: 'text-align:center;',
                    defaultValue: '-'
                },
                full_work_phone: {
                    label: 'Phone',
                    width: 300,
                    from: 'api',
                    prop: 'full_work_phone',
                    tdStyle: 'vertical-align: middle;',
                    style: 'text-align: center',
                    headStyle: 'text-align:center;',
                    defaultValue: '-'
                },
                tax_wsib_reporting_period_type_to_print: {
                    label: 'R.TYPE',
                    width: 150,
                    from: 'api',
                    prop: 'tax_wsib_reporting_period_type_to_print',
                    tdStyle: 'vertical-align: middle;',
                    style: 'text-align: center',
                    headStyle: 'text-align:center;',
                    defaultValue: '-'
                },
                tax_reporting_period_type_to_print: {
                    label: 'R.TYPE',
                    width: 150,
                    from: 'api',
                    prop: 'tax_reporting_period_type_to_print',
                    tdStyle: 'vertical-align: middle;',
                    style: 'text-align: center',
                    headStyle: 'text-align:center;',
                    defaultValue: '-'
                },
                account_accounting_type_num_label: {
                    label: 'Accounting Type',
                    width: 320,
                    from: 'api',
                    prop: 'account_accounting_type_num_label',
                    tdStyle: 'vertical-align: middle;',
                    style: 'text-align: center',
                    headStyle: 'text-align:center;',
                    defaultValue: '-'
                },
                account_storage_unit_number: {
                    label: 'Unit',
                    width: 120,
                    from: 'api',
                    prop: 'account_storage_unit_number',
                    tdStyle: 'vertical-align: middle;',
                    style: 'text-align: center',
                    headStyle: 'text-align:center;',
                    defaultValue: '-'
                },
                account_storage_shelf_number: {
                    label: 'Shelf',
                    width: 120,
                    from: 'api',
                    prop: 'account_storage_shelf_number',
                    tdStyle: 'vertical-align: middle;',
                    style: 'text-align: center',
                    headStyle: 'text-align:center;',
                    defaultValue: '-'
                },
                tax_done: {
                    label: 'Tax Done',
                    width: 220,
                    from: 'empty',
                    tdStyle: 'vertical-align: middle;',
                    style: 'text-align: center',
                    headStyle: 'text-align:center;',
                    defaultValue: '',
                    showDefaultValueOutSide: function (cell, data) {
                        return true
                    },
                    defaultValueOutSide: '_____________',
                    defaultValueOutSideStyle: 'bottom: 2px;',
                },
                t5_done: {
                    label: 'T5 Done',
                    width: 220,
                    from: 'empty',
                    tdStyle: 'vertical-align: middle;',
                    style: 'text-align: center',
                    headStyle: 'text-align:center;',
                    defaultValue: '',
                    showDefaultValueOutSide: function (cell, data) {
                        return true
                    },
                    defaultValueOutSide: '_____________',
                    defaultValueOutSideStyle: 'bottom: 2px;',
                },
                t4a: {
                    label: 'T4A Done',
                    width: 220,
                    from: 'empty',
                    tdStyle: 'vertical-align: middle;',
                    style: 'text-align: center',
                    headStyle: 'text-align:center;',
                    defaultValue: '',
                    showDefaultValueOutSide: function (cell, data) {
                        return true
                    },
                    defaultValueOutSide: '_____________',
                    defaultValueOutSideStyle: 'bottom: 2px;',
                },
                done: {
                    label: 'Done',
                    width: 220,
                    from: 'empty',
                    tdStyle: 'vertical-align: middle;',
                    style: 'text-align: center',
                    headStyle: 'text-align:center;',
                    defaultValue: '',
                    showDefaultValueOutSide: function (cell, data) {
                        return true
                    },
                    defaultValueOutSide: '_____________',
                    defaultValueOutSideStyle: 'bottom: 2px;',
                },
                fees_paid: {
                    label: 'Fees Paid',
                    width: 220,
                    from: 'empty',
                    tdStyle: 'vertical-align: middle;',
                    style: 'text-align: center',
                    headStyle: 'text-align:center;',
                    defaultValue: '',
                    showDefaultValueOutSide: function (cell, data) {
                        return true
                    },
                    defaultValueOutSide: '_____________',
                    defaultValueOutSideStyle: 'bottom: 2px;',
                },
                phone_icon: {local_icon: 'phone',
                    width: 100,
                    icon_width: '52px',
                    from: 'empty',
                    tdStyle: 'vertical-align: middle;',
                    style: 'text-align: center',
                    headStyle: 'text-align:center;',
                    defaultValue: '-',
                },
                cheque_number: {
                    label: 'Chk#',
                    width: 230,
                    from: 'func',
                    tdStyle: 'vertical-align: middle;',
                    style: 'text-align: center;font-size: 30px;',
                    headStyle: 'text-align:center;',
                    showDefaultValueOutSide: function (cell, data) {
                        if (this.func(cell, data) == '') return true
                        return false
                    },
                    defaultValueOutSide: '______________',
                    defaultValueOutSideStyle: 'bottom: 2px;',
                    func: function (cell, data) {
                        if(data.account_taxes_paid_by == 2) return 'PAID CLIENT'
                        if(data.account_taxes_paid_by == 3) return 'PAID ONLINE'
                        return ''
                    }
                },
                canada_flag: {
                    img: 'canada.png',
                    img_width: '92px',
                    width: 400,
                    from: 'func',
                    headStyle: 'text-align:center;',
                    tdStyle: 'vertical-align: middle;padding-top:6px',
                    style: 'text-align: center;font-size: 30px;',
                    showDefaultValueOutSide: function (cell, data) {
                        if (this.func(cell, data) == '') return true
                        return false
                    },
                    defaultValueOutSide: '___________________ . _____',
                    defaultValueOutSideStyle: 'bottom: 2px;',
                    func: function (cell, data) {
                        if(data.account_on_tax_check_management == 1) return ''
                        return ''
                    }
                },
                ontario_flag: {
                    img: 'ontario.png',
                    img_width: '92px',
                    width: 400,
                    from: 'empty',
                    tdStyle: 'vertical-align: middle;',
                    style: 'text-align: center;font-size: 30px;',
                    headStyle: 'text-align:center;',
                    showDefaultValueOutSide: function (cell, data) {
                        if (this.func(cell, data) == '') return true
                        return false
                    },
                    defaultValueOutSide: '___________________ . _____',
                    defaultValueOutSideStyle: 'bottom: 2px;',
                    func: function (cell, data) {
                        if(data.account_on_tax_check_management == 1) return ''
                        return ''
                    }
                },
                quebec_flag: {
                    img: 'quebec.png',
                    img_width: '92px',
                    width: 400,
                    from: 'empty',
                    tdStyle: 'vertical-align: middle;',
                    style: 'text-align: center;font-size: 30px;',
                    headStyle: 'text-align:center;',
                    showDefaultValueOutSide: function (cell, data) {
                        if (this.func(cell, data) == '') return true
                        return false
                    },
                    defaultValueOutSide: '___________________ . _____',
                    defaultValueOutSideStyle: 'bottom: 2px;',
                    func: function (cell, data) {
                        if(data.account_on_tax_check_management == 1) return ''
                        return ''
                    }
                },
                audit_from: {
                    label: 'From',
                    width: 270,
                    from: 'api',
                    prop: 'audit_from',
                    tdStyle: 'vertical-align: middle;',
                    style: 'text-align: center',
                    headStyle: 'text-align:center;',
                    defaultValue: '-'
                },
                audit_to: {
                    label: 'To',
                    width: 270,
                    from: 'api',
                    prop: 'audit_to',
                    tdStyle: 'vertical-align: middle;',
                    style: 'text-align: center',
                    headStyle: 'text-align:center;',
                    defaultValue: '-'
                },
                audit_completed: {
                    label: 'Completed',
                    width: 270,
                    from: 'api',
                    prop: 'audit_completed_to_text',
                    tdStyle: 'vertical-align: middle;',
                    style: 'text-align: center',
                    headStyle: 'text-align:center;',
                    defaultValue: '-'
                },
                account_related_company: {
                    label: 'Related',
                    width: 270,
                    from: 'api',
                    prop: 'account_related_company_to_text',
                    tdStyle: 'vertical-align: middle;',
                    style: 'text-align: center',
                    headStyle: 'text-align:center;',
                    defaultValue: '-'
                },
                account_partner_outside_canada: {
                    label: 'Partner',
                    width: 270,
                    from: 'api',
                    prop: 'account_partner_outside_canada_to_text',
                    tdStyle: 'vertical-align: middle;',
                    style: 'text-align: center',
                    headStyle: 'text-align:center;',
                    defaultValue: '-'
                },
                account_direct_deposit_since: {
                    label: 'Since',
                    width: 270,
                    from: 'api',
                    prop: 'account_direct_deposit_since',
                    tdStyle: 'vertical-align: middle;',
                    style: 'text-align: center',
                    headStyle: 'text-align:center;',
                    defaultValue: '-'
                },
                account_storage_since: {
                    label: 'Since',
                    width: 270,
                    from: 'api',
                    prop: 'account_storage_since',
                    tdStyle: 'vertical-align: middle;',
                    style: 'text-align: center',
                    headStyle: 'text-align:center;',
                    defaultValue: '-'
                },
                account_client_since: {
                    label: 'Client Since',
                    width: 270,
                    from: 'api',
                    prop: 'account_client_since',
                    tdStyle: 'vertical-align: middle;',
                    style: 'text-align: center',
                    headStyle: 'text-align:center;',
                    defaultValue: '-'
                },
                account_storage_fees:{
                    label: 'Monthly Fees',
                    width: 300,
                    from: 'api',
                    prop: 'account_storage_fees',
                    tdStyle: 'vertical-align: middle;',
                    style: 'text-align: center',
                    headStyle: 'text-align:center;',
                },
                account_direct_deposit_fees:{
                    label: 'Monthly Fees',
                    width: 300,
                    from: 'api',
                    prop: 'account_direct_deposit_fees',
                    tdStyle: 'vertical-align: middle;',
                    style: 'text-align: center',
                    headStyle: 'text-align:center;',
                },
                business_type_name:{
                    label: 'Business Type',
                    width: 380,
                    from: 'api',
                    prop: 'business_type_name',
                    headStyle: 'text-align:center;',
                    tdStyle: 'vertical-align: middle;',
                    style: 'text-align: center',
                },
                business_legal_structure_name:{
                    label: 'Legal Structure',
                    width: 380,
                    from: 'api',
                    prop: 'business_legal_structure_name',
                    headStyle: 'text-align:center;',
                    tdStyle: 'vertical-align: middle;',
                    style: 'text-align: center',
                },
                account_bookkeeper_user_name: {
                    label: 'User',
                    width: 260,
                    from: 'api',
                    prop: 'account_bookkeeper_user_name',
                    headStyle: 'text-align:center;',
                    tdStyle: 'vertical-align: middle;',
                    style: 'text-align: center',
                },
                account_date_of_close: {
                    label: 'Close Date',
                    width: 270,
                    from: 'api',
                    prop: 'account_date_of_close',
                    headStyle: 'text-align:center;',
                    tdStyle: 'vertical-align: middle;',
                    style: 'text-align: center',
                    defaultValue: '-'
                }
            }
        }
        return 0
    }
}

export default module
